// footer
.footer {
	.footer-links-section {
		padding-bottom: 6vh;
		.footer-text-title {
			color: $blue-dark;
			font-family: $font-family-secondary;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			display: block;
			width: 100%;
			max-width: 280px;
			padding-bottom: 5px;
			border-bottom: 1px solid $blue-dark;
		}
		.footer-text {
			color: #000;
			font-size: 16px;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
			a {
				color: $blue-dark;
				&:hover {
					font-weight: 600;
				}
			}
		}
		.vocabulary-term-list.social-media {
			ul {
				display: flex;
				gap: 0.35rem;
				margin-top: 0.5rem;
				li {
					.list-item-title {
						display: none;
					}
					i {
						color: $blue-dark;
						text-align: center;
						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
					}
					&:hover {
						i {
							font-weight: 600;
						}
					}
				}
			}
		}
	}
	.footer-outro-section {
		background: $blue-dark;
		padding-top: 6vh;
		margin-top: 12vh;
		position: relative;
		@include media-breakpoint-down(lg) {
			padding-top: 0;
		}
		div.footer-callout {
			position: absolute;
			top: -16vh;
			right: 15px;
			z-index: 990;
			@include media-breakpoint-down(lg) {
				top: -26vh;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
				display: flex;
				justify-content: center;
			}
			ul {
				max-width: 671px;
				width: 380px;
				max-width: 100%;
				height: 143px;
				border-radius: 6px;
				background: $orange-light;
				transition: 0.35s ease;
				&:hover {
					background: $orange;
					transition: 0.35s ease;
				}
				li {
					display: none;
					&:nth-child(1) {
						display: block;
					}
				}
				li a {
					display: flex !important;
					flex-flow: column-reverse;
					height: 100%;
					width: 95%;
					align-items: flex-start;
					padding: 1rem;
					text-decoration: none;
					i {
						position: absolute;
						right: 1rem;
						bottom: 1rem;
						color: #fff;
						font-size: 24px;
						font-style: normal;
						font-weight: 900;
						line-height: normal;
						@include media-breakpoint-down(lg) {
							right: 1.75rem;
						}
					}
					.list-item-suffix {
						color: #fff;
						font-family: $font-family-secondary;
						font-size: 15px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						border-bottom: 1px solid $white;
						padding-bottom: 5px;
						@include media-breakpoint-down(lg) {
							font-size: 15px;
						}
					}
					.list-item-title {
						text-align: left;
						color: #fff;
						font-family: $font-family-secondary;
						font-size: 24px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						@include media-breakpoint-down(lg) {
							font-size: 20px;
						}
					}
				}
			}
		}
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: -11.9vh;
			width: 100%;
			height: 12vh;
			background: url("/images/footer-top.svg");
			background-repeat: no-repeat;
			background-size: 101%;
			background-position: bottom;
			@include media-breakpoint-down(lg) {
				top: -11.5vh;
			}
		}
		.container {
			@include media-breakpoint-down(lg) {
				padding-top: 12vh;
			}
			.column {
				text-align: center;
				.logo {
					@include media-breakpoint-down(lg) {
						max-width: 227px;
						margin: auto;
					}
				}
				.footer-text {
					color: #fff;
					text-align: center;
					font-size: 16px;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
					a {
						text-decoration: underline;
						color: $white !important;
					}
				}
			}
			padding-bottom: 12vh;
		}
		.socket {
			padding: 15px;
			.column {
				text-align: center;
				.list {
					display: flex;
					justify-content: center;
					gap: 1rem;
					@include media-breakpoint-down(lg) {
						flex-flow: column;
						justify-content: center;
						gap: 0.45rem;
					}
					a {
						color: $white;
						text-decoration: none;
						font-size: 14px;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}
}
