// mini-sab
.mini-sab {
	@include media-breakpoint-down(lg) {
		background: $beige;
	}
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 900;

			margin: -15px 0 0 0;
			padding: 15px 0;
			background: $white;
			border-radius: 6px;
			background: white;
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
			height: max-content;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -250px;
				padding-left: 5px;
				padding-right: 5px;

				.column {
				}
			}
		}
	}
}
