// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}


// home usp section

// scrol animation
@keyframes moveRight {
	to {
		transform: translateX(100%);
	}
}
.home-usp-section {
	padding: 1.5rem 0;
	background: $beige;
	@include media-breakpoint-down(lg) {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	div.usps {
		display: flex;
		flex-flow: row wrap;
		flex-wrap: nowrap !important;
		justify-content: center;
		gap: 1rem;
		overflow: hidden;
		ul.usps {
			display: flex !important;
			flex-flow: row wrap;
			justify-content: center;

			gap: 1rem;
			@include media-breakpoint-down(lg) {
				flex-flow: column;
				max-width: 100%;
				padding: 0 1.5rem;
			}
			li {
				display: flex;
				flex-wrap: nowrap !important;
				min-width: max-content;
				gap: 1rem;
				position: relative;
				color: $blue-light;
				font-family: $font-family-secondary;
				font-size: 15px;
				font-style: normal;
				font-weight: 600;
				align-items: center;
				@include media-breakpoint-down(lg) {
					flex-wrap: unset !important;
					min-width: unset;
					align-items: center;
					flex-flow: row;
				}
				&::before {
					content: "\f336";
					font-family: $font-awesome;
					color: $blue-dark;
					font-size: 16px;
					font-style: normal;
					font-weight: 900;
					line-height: normal;
				}
				a {
					text-decoration: underline;
					text-underline-offset: 5px;
				}
			}
		}
	}
}

// home intro sections
.home-intro-section {
	padding: 6vh 0 6vh 0;
	background: $beige;
	position: relative;
	@include media-breakpoint-down(lg) {
		&.first {
			padding: 3vh 0 6vh 0;
		}
		padding: 9vh 0 6vh 0;
	}
	&::after {
		content: "";
		position: absolute;
		bottom: -9vh;
		left: 0;
		width: 100%;
		height: 9vh;
		background: url("/images/white-curve-top-2.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: bottom;
		pointer-events: none;
		z-index: 800;
		@include media-breakpoint-down(lg) {
			background-position: top;
		}
	}
	.container {
		&.container-two-columns {
			margin-bottom: 6vh;
			@include media-breakpoint-down(lg) {
				margin-bottom: 1rem;
			}
			.one {
				max-width: 60% !important;
				flex-basis: 60% !important;
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}

				.wysiwyg {
					&.first {
						font-size: 24px;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
						@include media-breakpoint-down(lg) {
							font-size: 20px;
						}
					}
					&.second {
						font-size: 16px;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
					}
				}
			}
			.two {
				max-width: 40% !important;
				flex-basis: 40% !important;
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
				.image {
					display: flex;
					justify-content: flex-end;
					padding-right: 0;
					@include media-breakpoint-down(lg) {
						width: 100%;
						justify-content: flex-start;
						padding-left: 0;
						align-items: center;
						img {
							width: 600px;
							margin-top: 1rem;
						}
					}
				}
			}
		}
	}
	// second home-intro
	&.second {
		background: $white;
		&::after {
			display: none;
		}
		.grid.first {
			margin-bottom: 6vh;
			margin-top: -12vh;
			position: relative;
			z-index: 900;
			.item {
				max-width: 50% !important;
				flex-basis: 50% !important;
				display: none;

				&:nth-child(1),
				&:nth-child(2) {
					display: unset;
				}

				.card {
					@extend .card-overlay-home;
				}
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
			}
		}
		.grid.second {
			padding-right: 0;
			margin-left: auto;
			margin-right: 0;
			overflow: hidden;
			padding-bottom: 6vh;
			.slider {
				margin-left: auto;
				margin-right: 0;
				.owl-nav {
					position: absolute;
					bottom: 0;
					left: 5px;
					button {
						left: 0;
						transform: scaleX(-1);
						&.owl-next {
							left: 40px;
							transform: scaleX(1);
						}
						i {
							&::before {
								content: "\f138";
							}
						}
					}
				}
				.item {
					max-width: unset !important;
					flex-basis: unset !important;
					.card {
						border: 0;
						.card-image {
							display: flex;
							justify-content: flex-start;
							padding-left: 15px;
							i {
								color: $blue-light;
								font-size: 32px;
								font-style: normal;
								font-weight: 900;
								line-height: normal;
								transition: 0.35s ease;
							}
						}
						.card-title {
							color: $blue-dark;
							font-family: $font-family-secondary;
							font-size: 20px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
							display: block;
							border-bottom: 1px solid $blue-light;
						}
						.card-description {
							color: #000;
							font-family: $font-family-primary;
							font-size: 16px;
							font-style: normal;
							font-weight: 300;
							line-height: normal;
						}
					}
					&:hover {
						.card-image i {
							color: $primary;
							transition: 0.35s ease;
						}
					}
				}
			}
		}
	}
}

// home-facilities-section
.home-facilities-section {
	padding: -9vh 0;
	position: relative;
	z-index: 900;
	background: $beige;
	overflow: hidden;
	&::before {
		content: "";
		position: absolute;
		left: 50%;
		top: 0;
		width: 200%;
		height: 50%;
		background: $white;
		clip-path: ellipse(50% 100% at 50% 100%);
		z-index: -1;
		transform: translateY(-70%) translateX(-50%) scaleY(-1);
	}
	&::after {
		content: "";
		position: absolute;
		left: 50%;
		bottom: 0;
		width: 200%;
		height: 50%;
		background: $white;
		clip-path: ellipse(50% 100% at 50% 100%);
		z-index: -1;
		transform: translateY(70%) translateX(-50%);
	}
	.container {
		z-index: 200;
		&.first {
			.owl-carousel {
				.owl-item {
					@include media-breakpoint-down(lg) {
						min-height: 35vh;
					}
				}
				.owl-dots {
					display: none;
				}
				.owl-nav {
					position: absolute;
					left: 0;
					bottom: 100px;
					button {
						i {
							font-size: 32px;
							font-style: normal;
							font-weight: 900;
							line-height: normal;
							&::before {
								content: "\f138";
							}
						}
						&.owl-prev {
							left: 1.5rem;
							transform: scaleX(-1) translateY(-50%);
						}
						&.owl-next {
							left: 70px;
							transform: scaleX(1) translateY(-50%);
						}
					}
				}
			}
			.card {
				@extend .card-overlay-home;
				&::after {
					display: none;
				}
				.card-btn {
					@extend .btn-primary;
				}
				@include media-breakpoint-down(lg) {
					min-height: 35vh;
					.card-image {
						min-height: 35vh;
						img {
							height: 100%;
							object-fit: cover;
						}
					}
				}
			}
		}
		&.second {
			margin-top: 3vh;
			.grid {
				.item {
					max-width: 50% !important;
					flex-basis: 50% !important;
					display: none;

					&:nth-child(1),
					&:nth-child(2) {
						display: unset;
					}

					.card {
						@extend .card-overlay-home;
					}
					@include media-breakpoint-down(lg) {
						max-width: 100% !important;
						flex-basis: 100% !important;
					}
				}
			}
		}
	}
}

// home outro
.home-outro-section {
	padding: 6vh 0;
	.subtitle {
		font-size: 24px;
	}
	.description {
		max-width: 65%;
		font-size: 24px;
		font-style: normal;
		font-weight: 300;
		line-height: normal;
		@include media-breakpoint-down(lg) {
			max-width: 100%;
			font-size: 20px;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		.wysiwyg{
			max-width: 50%;
			@include media-breakpoint-down(lg) {
				max-width: 100%;
			}
		}
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .list-unstyled;

			li {
				margin: 0 15px 0 0;
				color: $green;
				font-weight: 600;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
