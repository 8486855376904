// header
.header {
	z-index: 2000;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: $beige-light;
	transition: 0.5s;

	&::after {
		content: "";
		position: absolute;
		bottom: -9vh;
		left: 0;
		width: 100%;
		height: 9vh;
		background: url("/images/white-curve-top.svg");
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: bottom;
		pointer-events: none;
		z-index: 800;
		@include media-breakpoint-down(lg) {
			background-position: top;
			bottom: -5.5vh;
			height: 6vh;
		}
	}
	&.sticky {
		box-shadow: $shadow;
		position: sticky;
		.logo {
			background-position: top -100px left 0px;
			transition: 0.35s ease;
			@include media-breakpoint-down(lg) {
				background-position: top 0px left 0px;
			}
			img {
				width: 230px;
				transition: 0.35s ease;
				@include media-breakpoint-down(lg) {
					width: 110px;
				}
			}
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: center;
				justify-content: flex-end;
				padding-left: 2.5rem;
				padding-right: 2.5rem;
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
					padding-left: 0.5rem;
					padding-right: 0.5rem;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.top {
			padding: 0.6rem 15px;
			@include media-breakpoint-down(lg) {
				padding: 0.6rem 1.5rem;
			}
		}
		&.bottom {
			padding: 0.5rem 15px 0.25rem 15px;

			@include media-breakpoint-down(lg) {
				padding: 0.5rem 0.5rem 7px 0.5rem;
				.container-holder {
					margin-right: 0;
					margin-left: 0;
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		.navbar-toggler {
			background: $blue-dark;
			border-radius: 3px;
			width: 34px;
			aspect-ratio: 1/1;
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 0.3rem;
			i {
				color: $white;
				font-size: 18px;
				transition: 0.35s ease;
			}
			&[aria-expanded="true"] {
				i {
					transform: scaleX(-1);
					transition: 0.35s ease;
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 700px;
		margin: 0;
		position: fixed;
		left: 0;
		top: 0;
		background: url("/images/logo-bg.svg");
		background-size: 100%;
		height: 35vh;
		background-repeat: no-repeat;
		transition: 0.35s ease;
		pointer-events: none;
		z-index: 900;
		@include media-breakpoint-down(lg) {
			width: 300px;
		}
		a {
			@extend .d-block;
			pointer-events: all;
			@include media-breakpoint-down(lg) {
				max-width: max-content;
			}
			img {
				width: 330px;
				margin: 1rem 0 2rem 2.5rem;
				transition: 0.35s ease;
				@include media-breakpoint-down(lg) {
					width: 130px;
					margin: 1.5rem 15px;
				}
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 1;

			padding: 9vh 0 6vh 0;

			.navbar-nav {
				.nav-item {
					border-bottom: 1px solid $green;

					.nav-link {
						font-family: $font-family-primary;
						color: $blue-dark;
						font-size: 16px;
						font-weight: 600;
					}

					&.active {
						.nav-link {
							color: $orange !important;
							font-weight: 600;
						}
					}

					.dropdown-menu {
						position: static !important;
						border: 0 !important;
						box-shadow: none !important;
						margin: 0 !important;
						padding: 0 !important;
						transform: none !important;
						background: none !important;

						&::after,
						&::before {
							display: none;
						}

						.nav-item {
							border-bottom: 0;
							padding-left: 1rem;
						}

						.nav-link {
							font-size: 16px;
							font-weight: 200;
							font-family: $font-family-primary;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			max-width: fit-content;
			margin-right: 1.5rem;
			.navbar-nav {
				gap: 1.5rem;
				.nav-item {
					.nav-link {
						color: $blue-dark;
						font-family: $font-family-secondary;
						font-size: 15px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						text-underline-offset: 8px;
					}
					&.active,
					&:hover {
						.nav-link {
							text-decoration: underline 2px $blue-dark;
						}
					}
					.dropdown-menu.show {
						box-shadow: none;
						border: 0;
						background: $beige-light;
						min-width: 12rem;
						text-align: center;
						&::after,
						&::before {
							display: none;
						}
						.nav-link {
							text-decoration: none;
							&:hover {
								text-decoration: none;
								color: $orange;
							}
						}
					}
				}
			}
			&#secondary_menu {
				.navbar-nav {
					gap: 0.5rem;

					.nav-item {
						.nav-link {
							font-size: 13px;
							font-style: normal;
							font-weight: 400;
							line-height: normal;
							text-underline-offset: 4px;
						}

						&.active,
						&:hover {
							.nav-link {
								text-decoration: underline 1px $blue-dark;
							}
						}
						.dropdown-menu.show {
							min-width: 100%;
							text-align: left;
							.nav-link {
								padding: 0 0.5rem;
							}
						}
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		li {
			box-shadow: none !important;
			a {
				color: #33649c;
				font-family: $font-family-secondary;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				box-shadow: none !important;
				border: 0 !important;
			}
			&.active,
			&:hover {
				border: 0;
				box-shadow: none;
				a {
					font-weight: 900;
				}
			}
		}
	}

	// book-button
	.book-button {
		a {
			@extend.btn, .btn-primary;
			@include media-breakpoint-down(lg) {
				padding: 7px 15px !important;
				font-size: 12px;
			}
		}
	}
}
