// container
.container {
	@include media-breakpoint-down(lg) {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}
	@media (min-width: 1280px) {
		max-width: 1210px;
	}

	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	border-radius: 6px;
	border: none;
	box-shadow: none;
	background: $beige;
	.card-title {
		font-weight: 700;
		color: $blue-dark;
		font-family: $font-family-secondary;
	}
	.card-subtitle {
		font-family: $font-family-primary;
		font-size: 15px;
		font-weight: 700;
		font-family: $font-family-secondary;
		@include media-breakpoint-down(lg) {
			font-size: 13px;
		}
	}
}

// card-assortiment
.card-assortiment {
	.card-image-caption {
		background: $green !important;
	}
}

// card-overlay
.card-overlay-home {
	border-radius: 6px;
	position: relative;

	&::after {
		content: "\f138";
		position: absolute;
		right: 1.5rem;
		bottom: 1rem;
		font-size: 32px;
		color: $white;
		z-index: 900;
		font-family: $font-awesome;
		font-weight: 600;
	}

	.card-img-overlay {
		border-radius: 6px;
		display: flex;
		align-items: flex-end;
		background: linear-gradient(
			180deg,
			rgba(#000, 0) 0%,
			rgba(#000, 0.36) 100%
		);

		.card-caption {
			display: flex;
			flex-flow: column;
			padding-left: 1rem;

			.card-description {
				display: none;
			}

			.card-title {
				font-family: $font-family-secondary;
				font-size: 36px !important;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				margin-bottom: 0;
				@include media-breakpoint-down(lg) {
					font-size: 20px !important;
				}
			}

			.card-subtitle {
				order: -1;
				font-family: $font-family-secondary;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				margin-bottom: 0;
				@include media-breakpoint-down(lg) {
					font-size: 13px;
				}
			}
		}
	}
}

// titles

.title:not(.tbs-translate) {
	font-size: 40px;
	font-weight: 700;
	color: $blue-dark;
	position: relative;
	width: 661px;
	@include media-breakpoint-down(lg) {
		max-width: 100%;
		font-size: 24px;
	}
	.title {
		padding-bottom: 8px;
		border-bottom: 2px solid $blue-light;
	}
}

.subtitle {
	font-family: $font-family-secondary;
	font-weight: 300;
	font-size: 24px;
	color: $blue-light;
	@include media-breakpoint-down(lg) {
		font-size: 16px;
	}
}

// images
.gallery-item,
.gallery-link,
.gallery-item img {
	border-radius: 6px;
}
