// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors

$blue-light: #8faadc;
$blue-dark: #33649c;
$beige: #ece2d0;
$beige-light:#F2EFE9;
$orange: #e4572e;
$orange-light: #e2856e;
$green: #b9c6ae;
$yellow: #fac748;

$white: #fff;
$black: #000;

// theme-colors
$primary: $orange-light;
$secondary: $blue-dark;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $blue-dark;
$link-decoration: underline;
$link-hover-color: inherit;

// typography
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Parkinsans:wght@300..800&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
$font-family-primary: "Outfit", serif;
$font-family-secondary: "Parkinsans", sans-serif;
$font-special: "Roboto Mono", monospace;

$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px

$h1-font-size: $font-size-base * 2.5; // 40px
$h2-font-size: $font-size-base * 2; // 32px
$h3-font-size: $font-size-base * 1.75; // 28px
$h4-font-size: $font-size-base * 1.5; // 24px
$h5-font-size: $font-size-base * 1.25; // 20px
$h6-font-size: $font-size-base; // 16px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;
$headings-color: $blue-dark;

// contextual
$light: $white;
$dark: $beige;
$text-light: $black;
$text-dark: $black;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: none;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
