// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-dots,
	.owl-nav,
	.owl-caption {
		display: none;
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 65vh;
	min-height: 540px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 80vh;
	}
}

// eyecatcher
.eyecatcher {
	position: relative;
	overflow: hidden;

	// vimeo-video
	.vimeo-video {
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 100vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				width: 177.78vh;
			}
		}
	}
}

section.eyecatcher.large {
	position: relative;
	.vocabulary-term-list.scrollbar {
		cursor: default;
		position: absolute;
		bottom: 0px;
		left: 0;
		min-width: 100%;
		height: 128px;
		overflow: hidden;
		z-index: 700;

		display: flex !important;
		flex-flow: row nowrap !important;
		ul {
			position: relative;
			display: block;
			min-width: fit-content;
			gap: 0.3rem;
			animation: move 30s linear infinite;
			&.clone {
				order: 2;
			}
			li {
				font-family: $font-special;
				font-size: 128px;
				line-height: 179px;
				font-weight: 700;
				color: transparent;
				-webkit-text-stroke-width: 2px;
				-webkit-text-stroke-color: white;
				text-rendering: geometricPrecision;

				transition: 1s ease;
				&:hover {
					color: $white;
					transition: 1s ease;
					text-shadow: 4px 4px 10px rgba(#000, 0.15);
				}
			}
		}
	}
}

// scrol animation
@keyframes move {
	to {
		transform: translateX(-100%);
	}
}
